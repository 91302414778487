<fieldset class="fieldSetForm" [ngClass]="isOfflineProjectSupportEnabled?'nonEditable':''">
<div class="row form-group zs_field">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 row"
        *ngIf="(!((postObject['inLevelCohortSelected']) || postObject['outLevelCohortSelected']) && type !== 'inTask')">
        <label class="container_checkbox newContainerClass">
          <span>{{ typeLabel }}</span>
          <input *ngIf="action == 'inLevel'" type="checkbox" [disabled]="index == 0"
            [checked]="postObject['inLevelCohortSelected']" (click)="switchPrimary()">
          <input *ngIf="action == 'outLevel'" type="checkbox" [checked]="postObject['outLevelCohortSelected']"
            (click)="switchPrimary()">
          <span class="checkmark_checkbox"></span>
        </label><br>
<!--        <span class="information-button" *ngIf="isDSS == true && action == 'inLevel'">-->
<!--          <i class="fa fa-info-circle" style="padding-left: 8px;" aria-hidden="true"></i>-->
<!--          <div class="hovercard">-->
<!--            Inlevel cohort cannot be added when DSS is enabled!-->
<!--          </div>-->
<!--        </span>-->
        <span *ngIf="index == 0 && action == 'inLevel'" style="padding-top: 6px;
        padding-left: 10px;">
          <h6><b>Note</b> : There is no previous task</h6>
        </span>
      </div>
      <div class="col-md-5 row" *ngIf="(postObject['inLevelCohortSelected'] || postObject['outLevelCohortSelected'])  && type !== 'inTask'">
        <label class="container_checkbox newContainerClass">
          <span>{{ typeLabel }}</span>
          <input *ngIf="action == 'inLevel'" type="checkbox" [disabled]="index == 0"
            [checked]="postObject['inLevelCohortSelected']" (click)="switchPrimary()">
          <input *ngIf="action == 'outLevel'" type="checkbox" [checked]="postObject['outLevelCohortSelected']"
            (click)="switchPrimary()">
          <span class="checkmark_checkbox"></span>
        </label><br>
<!--        <span class="information-button" *ngIf="isDSS == true && action == 'inLevel'">-->
<!--          <i class="fa fa-info-circle" style="padding-left: 8px;" aria-hidden="true"></i>-->
<!--          <div class="hovercard">-->
<!--            Inlevel cohort cannot be added when DSS is enabled!-->
<!--          </div>-->
<!--        </span>-->
        <span *ngIf="index == 0 && action == 'inLevel'" style="padding-top: 6px;
        padding-left: 10px;">
          <h6><b>Note</b> : There is no previous task</h6>
        </span>
      </div>
      <div class="col-md-5 row" *ngIf="type ==='inTask'"></div>
      <div class="col-md-1" *ngIf="(postObject['inLevelCohortSelected']) || postObject['outLevelCohortSelected'] || type==='inTask'">
        &nbsp; <br />
        <button class="btn btn-primary" (click)="addcohortList()">
          <b><i class="fa fa-plus"></i></b>
        </button>
      </div>

      <div class="col-md-6" *ngIf="((postObject['inLevelCohortSelected']) || postObject['outLevelCohortSelected']) && type!=='inTask'">
        <div class="row">
          <div class="col-md-6 my-padding ">
            <label>Parent Condition Logic:</label>
          </div>
          <div class="col-md-6">
            <input type="text" [(ngModel)]="parentLogic[type]" class="form-control" (keydown)="onKeydown($event, item)">
          </div>
        </div>
      </div>

      <div class="col-md-6" *ngIf="type==='inTask'">
        <div class="row">
          <div class="col-md-6 my-padding ">
            <label>Parent Condition Logic:</label>
          </div>
          <div class="col-md-6">
            <input type="text" [(ngModel)]="parentLogic" class="form-control" (keydown)="onKeydown($event, item)" (ngModelChange)="onParentConditionChange()">
          </div>
        </div>
      </div>

      <div *ngIf="(postObject['inLevelCohortSelected']) || postObject['outLevelCohortSelected'] || type==='inTask'" class="col-md-11 card">
        <div class="multi-cohort-list" *ngFor="let item of muitiCohert; let ind = index">
          <div class="">
            <div class="col-md-12 adjustpadding">
              <div class="row">
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-4"><label>Table Id:</label></div>
                    <div class="col-md-8"><input type="text" class="form-control" [(ngModel)]="item.tableId"
                        (keydown)="onKeydown($event, item)"></div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6 my-padding ">
                      <label>Multiple Condition Logic:</label>
                    </div>
                    <div class="col-md-6" *ngIf="type == 'inLevel'">
                      <ng-select [items]="multiplelogic" [(ngModel)]="item.multipleConditionLogic[type]"
                        bindValue="value" bindLabel="label" [allowClear]="false" [multiple]="false">
                      </ng-select>
                    </div>
                    <div class="col-md-6" *ngIf="type == 'outLevel'">
                      <ng-select [items]="multiplelogic" [(ngModel)]="item.multipleConditionLogic[type]"
                        bindValue="value" bindLabel="label" [allowClear]="false" [multiple]="false">
                      </ng-select>
                    </div>
                    <div class="col-md-6" *ngIf="type == 'inTask'">
                      <ng-select [items]="multiplelogic" [(ngModel)]="item.multipleConditionLogic[type]"
                        bindValue="value" bindLabel="label" [allowClear]="false" [multiple]="false">
                      </ng-select>
                    </div>
                  </div>

                </div>

                <div class="col-md-1" *ngIf="ind != 0">
                  <div class="row">
                    <button class="btn btn-danger" (click)="removeCards(item, ind)">
                      <b><i class="fa fa-trash" style="color: white;"></i></b>
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <h6>Condition Name:</h6>
                <input type="text" class="condition" *ngIf="show" [(ngModel)]="item.conditionObj['conditionName']"
                  class="form-control" />
              </div>
              <div class="col-md-3">
                <h6>Cohorting Type:</h6>
                <ng-select [items]="cohortingTypes" placeholder="Select Cohort Type"
                  [(ngModel)]="item.conditionObj['cohortType']" (ngModelChange)="cohortingTypeChange($event)"
                  [allowClear]="false" [multiple]="false" bindValue="value" bindLabel="label">
                </ng-select>
              </div>
              <div class="col-md-3">
                <h6>Select Task:</h6>
                <ng-select [items]="selectTaskTypes" [(ngModel)]="item.conditionObj['task']" (ngModelChange)="inputChange($event, selectTaskTypes, ind, item)"
                   [allowClear]="false" [multiple]="false" bindValue="value"
                  bindLabel="label">
                </ng-select>
              </div>
              <div class="col-md-3" *ngIf="optionToShow === 'showSelectImages' && item.conditionObj['cohortType'] ==='Image Based'">
                <h6>Select Image:</h6>
                <ng-select [items]="selectEmailImages"
                  [(ngModel)]="item.conditionObj['selectedImage']" [allowClear]="false" [multiple]="false" bindValue="value"
                  bindLabel="label">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}" class="custpom-option" style="font-size: 16px !important;">{{item.label}}</div>
                    </ng-template>
                </ng-select> <!-- (ngModelChange)="inputChange($event, selectEmailImages, ind, item)"-->
              </div>
              <div *ngIf="isEdit" class="col-md-1" style="margin-top: 26px; padding-bottom: 0px">
                <button class="btn btn-info btn-sm" (click)="updateOption(ind, item)">
                  <i class="fa fa-edit"></i></button>
              </div>
              <div class="col-md-1" style="margin-top: 26px; padding-bottom: 0px">
                <!-- <button class="btn btn-sm btn-info" (click)="addObjectTreatment(ind)"  -->
                <button class="btn btn-sm btn-info" (click)="addObjectTreatment(ind, item)"
                  (ngModelChange)="editList(ind)" [disabled]="!isValid">
                  <i class="fa fa-plus"></i></button>
              </div>
            </div>
            <div class="row"
              *ngIf="showConditionBlock && (item.conditionObj['cohortType'] == 'Specific Input' && item.conditionObj['task'])">
              <div class="col-md-3">
                <h6>Condition in task</h6>
                <ng-select [items]="conditionInTaskTypes" (ngModelChange)="handleSingleInputDropdown($event, ind, item)"
                  placeholder="Select Cohort Type" [(ngModel)]="item.conditionObj['conditionInTask']" bindValue="value"
                  bindLabel="label" [allowClear]="false" [multiple]="false">
                </ng-select>
              </div>
              <div class="col-md-3" *ngIf="!isMR">
                <h6>Select Input</h6>
                <ng-select [items]="SELECT_INPUT"
                  [disabled]="!item.conditionObj['task'] || !item.conditionObj['conditionInTask']" bindValue="value"
                  bindLabel="label" [(ngModel)]="item.conditionObj['selectInput']" [multiple]="true">
                </ng-select>
              </div>
              <div class="col-md-2" *ngIf="!isMR">
               <h6>Operator</h6>
                  <ng-select [items]="operator_list" [(ngModel)]="item.conditionObj['operator']" bindValue="value"
                        bindLabel="label" [multiple]="false">
                  </ng-select>
              </div>
              <ng-container *ngIf="isMR && questionIDs">
                <div class="col-md-3">
                  <h6>Select Question</h6>
                  <ng-select [items]="questionIDs" [(ngModel)]="cohortData.questionID" bindValue="value"
                    bindLabel="label" (ngModelChange)="changeQuestion($event)">
                  </ng-select>
                </div>


                <ng-container *ngIf="questionDetails && questionDetails.type === 'sum'">
                  <div class="col-md-5">
                    <h6>Select Option ID</h6>
<!--                    <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" [multiple]="true"-->
<!--                      (ngModelChange)="changeOptionSumType($event)">-->
<!--                    </ng-select>-->
                    <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" bindValue="value" [multiple]="true"
                               bindLabel="label" (ngModelChange)="changeOptionSumType($event)">
                      <ng-template ng-label-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                    </ng-select>

                  </div>
                  <ng-container *ngIf="cohortData.options.length > 0 && shareAllocationOptionShow">
                    <div class="col-md-2">
                      <h6>Operator</h6>
                      <ng-select [items]="cohortData.operatorsList" [(ngModel)]="cohortData.operator" bindValue="value"
                        bindLabel="label" [multiple]="false">
                      </ng-select>
                    </div>
                    <div class="row">

                      <div class="col-md-3">
                        <h6>Compare Task</h6>
                        <ng-select [items]="compareSelectTaskTypes" [(ngModel)]="cohortData.compare_task"
                           [allowClear]="false" (ngModelChange)="getCompareQuestionsByTask('Selected an Option', ind, item, cohortData)"
                          [multiple]="false" bindValue="value" bindLabel="label">
                        </ng-select>
                      </div>

                      <div class="col-md-3">
                        <h6>Compare Question</h6>
                        <ng-select [items]="compareQuestionList" [(ngModel)]="cohortData.compare_questionID"
                          (ngModelChange)="setCompareOption($event)" [multiple]="false" bindValue="value"
                          bindLabel="label">
                        </ng-select>
                      </div>
                      <div class="col-md-5" *ngIf="selectedComparsionQuestion?.type != 'openEndedNumeric'">
                        <h6>Compare Option Id</h6>
<!--                        <ng-select [items]="compareOptionList" [(ngModel)]="cohortData.compare_options" [multiple]="true"-->
<!--                          (ngModelChange)="validateCompareOption(cohortData, 'option')"-->
<!--                                   bindValue="value" bindLabel="label">-->
<!--                        </ng-select>-->
                        <ng-select [items]="compareOptionList" [(ngModel)]="cohortData.compare_options" bindValue="value" [multiple]="true"
                               bindLabel="label" (ngModelChange)="validateCompareOption(cohortData, 'option')">
                      <ng-template ng-label-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                    </ng-select>
                      </div>
                      <div class="col-md-3">
                        <h6>Value</h6>
                        <input type="number" oninput="validity.valid||(value='');" class="form-control"
                               (ngModelChange)="validateCompareOption(cohortData, 'value')"
                          [(ngModel)]="cohortData.value" [attr.max]="questionDetails.maxValue">
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container
                    *ngIf="questionDetails?.type !== 'openEndedNumeric' && questionDetails?.type !== 'dropdownSelection' && questionDetails?.type !== 'dateCalender' && questionDetails?.type !== 'sum'">
                    <div class="col-md-5">
                      <h6>Select Option ID</h6>
<!--                      <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" [multiple]="true"-->
<!--                        bindValue="value" bindLabel="label" (ngModelChange)="changeQuestion($event)">-->
<!--                      </ng-select>-->
                      <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" bindValue="value" [multiple]="true"
                               bindLabel="label" (ngModelChange)="changeQuestion($event)">
                      <ng-template ng-label-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                    </ng-select>
                    </div>
                  </ng-container>

                <ng-container *ngIf="questionDetails">
                  <ng-container *ngIf="operatorBasedQuestions.indexOf(questionDetails.type) > -1">
                    <div class="col-md-2">
                      <h6>Operator</h6>
                      <ng-select [items]="cohortData.operatorsList" [(ngModel)]="cohortData.operator" bindValue="value"
                        bindLabel="label" [multiple]="false">
                      </ng-select>
                    </div>
                    <div class="row col-12">

                      <div class="col-md-3">
                        <h6>Compare Task</h6>
                        <ng-select [items]="compareSelectTaskTypes" [(ngModel)]="cohortData.compare_task"
                           [allowClear]="false" (ngModelChange)="getCompareQuestionsByTask('Selected an Option', ind, item, cohortData)"
                          [multiple]="false" bindValue="value" bindLabel="label">
                        </ng-select>
                      </div>

                      <div class="col-md-3">
                        <h6>Compare Question</h6>
                        <ng-select [items]="compareQuestionList" [(ngModel)]="cohortData.compare_questionID"
                          (ngModelChange)="setCompareOption($event)" [multiple]="false" bindValue="value"
                          bindLabel="label">
                        </ng-select>
                      </div>
                      <div class="col-md-5" *ngIf="selectedComparsionQuestion?.type != 'openEndedNumeric'">
                        <h6>Compare Option</h6>
<!--                        <ng-select [items]="compareOptionList" [(ngModel)]="cohortData.compare_options" [multiple]="true"-->
<!--                          (ngModelChange)="validateCompareOption(cohortData, 'option')"-->
<!--                                   bindValue="value" bindLabel="label">-->
<!--                        </ng-select>-->
                        <ng-select [items]="compareOptionList" [(ngModel)]="cohortData.compare_options" bindValue="value" [multiple]="true"
                               bindLabel="label" (ngModelChange)="validateCompareOption(cohortData, 'option')">
                      <ng-template ng-label-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                    </ng-select>
                      </div>
                      <div class="col-md-3">
                        <h6>Value</h6>
                        <input type="number" oninput="validity.valid||(value='');" class="form-control"
                          [(ngModel)]="cohortData.value"
                               (ngModelChange)="validateCompareOption(cohortData, 'value')"
                               [attr.max]="questionDetails.maxValue">
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="CalenderBasedQuestions.indexOf(questionDetails.type) > -1">
                    <div class="col-md-2">
                      <h6>Operator</h6>
                      <ng-select [items]="cohortData.operatorsList" [(ngModel)]="cohortData.operator" bindValue="value"
                        bindLabel="label" [multiple]="false">
                      </ng-select>
                    </div>
                    <div class="col-md-3">
                      <h6>Calender</h6>
                      <!-- <angular2-date-picker name="date" [(ngModel)]="cohortData.date" [settings]="DateSettings">
                    </angular2-date-picker> -->
                      <input class="cal" placeholder="Select Date" [(ngModel)]="cohortData.date"
                        [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
                      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                    </div>
                  </ng-container>


                  <ng-container *ngIf="questionDetails && questionDetails.type === 'singleSelect' || questionDetails.type === 'multiSelect'">
                    <div class="col-md-2">
                      <h6>Operator</h6>
                      <ng-select [items]="operator_list" [(ngModel)]="cohortData.operator" bindValue="value"
                        bindLabel="label" [multiple]="false">
                      </ng-select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="questionDetails.type == 'dropdownSelection'">
                    <div class="col-md-5">
                      <h6>Select Option ID</h6>
<!--                      <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" bindValue="value"-->
<!--                        bindLabel="label" (ngModelChange)="changeOption()">-->
<!--                      </ng-select>-->
                      <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" bindValue="value" [multiple]="true"
                               bindLabel="label" (ngModelChange)="changeOption()">
                      <ng-template ng-label-tmp let-item="item">
                        <span [innerHTML]="item.label"></span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [innerHTML]="item.label" title=""></span>
                      </ng-template>
                    </ng-select>

                    </div>
                    <div class="col-md-2">
                      <h6>Operator</h6>
                      <ng-select [items]="operator_list" [(ngModel)]="cohortData.operator" bindValue="value"
                        bindLabel="label" [multiple]="false">
                      </ng-select>
                    </div>
                    <div class="col-md-3">
                      <h6>Dropdown</h6>
                      <ng-select [items]="cohortDropdown" [(ngModel)]="cohortData.dropdownOptions" bindValue="value"
                        bindLabel="label" (ngModelChange)="changeDropdown($event)" [multiple]="true">
                      </ng-select>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <!--For image based MR changes-->
            <div class="row" *ngIf="optionToShow === 'showSelectQuestion' && item.conditionObj['cohortType'] === 'Image Based'">
              <div class="col-md-3">
                <h6>Select Question</h6>
                <ng-select [items]="selectQuestionOptions" [(ngModel)]="item.conditionObj['questionID']" bindValue="value"
                  bindLabel="label" (ngModelChange)="questionImageChanges($event, 'questionID', item.conditionObj); changeQuestion($event)">
                </ng-select>
              </div>
              <div class="col-md-3" *ngIf="item.conditionObj['questionID']">
                <h6>Image Type</h6>
                <ng-select [items]="qstnTypeOptions" [(ngModel)]="item.conditionObj['qstnType']" bindValue="value"
                  bindLabel="label" (ngModelChange)="questionImageChanges($event, 'qstnType', item.conditionObj )">
                </ng-select>
              </div>
              <div class="col-md-3" *ngIf="item.conditionObj['qstnType'] === 'optionType'">
                <h6>Select Option</h6>
                <ng-select [items]="selectOptions" [(ngModel)]="item.conditionObj['imageOption']" bindValue="value"
                  bindLabel="label" (ngModelChange)="questionImageChanges($event, 'imageOption', item.conditionObj)">
                </ng-select>
              </div>
              <div class="col-md-3" *ngIf="item.conditionObj['qstnType'] === 'questionType' || (item.conditionObj['qstnType'] === 'optionType' &&  item.conditionObj['imageOption'])"><!--*ngIf="item.conditionObj['qstnType'] === 'questionType'"-->
                <h6>Select Image</h6>
                <ng-select [items]="selectImages" [(ngModel)]="item.conditionObj['selectedImage']" bindValue="value"
                  bindLabel="label">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{item.label}}" class="custpom-option"  style="font-size: 16px !important;">{{item.label}}</div>
                    </ng-template>
                </ng-select>
              </div>
            </div>
            <!--image based MR changes end-->
            <div class="row">
              <div class="col-md-12"
              *ngIf="(item.conditionObj['cohortType'] === 'Response Based' || item.conditionObj['cohortType'] === 'Image Based') && item.conditionObj['task']">
                <div class="pull-right">
                  <label class="container_checkbox">
                    <p>Has Response</p>
                    <input type="checkbox" float="right" checked="item.conditionObj['hasResponse']"
                      (click)="changeHasResponse(ind, item)" [(ngModel)]="item.conditionObj['hasResponse']">
                    <span class="checkmark_checkbox"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <table class="tableStyle table table-bordered">
                  <thead>
                    <tr>
                      <th style="width:118px">Condition No</th>
                      <th>Condition Name</th>
                      <th>Condition Type</th>
                      <th>Selected Cohort Values</th>
                      <th>Question Id</th>
                      <th>Compare Task</th>
                      <th>Compare Question Id</th>
                      <th>Dropdown</th>
                      <th>Has Reponse</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="postObject.conditions[ind]">
                    <tr *ngFor="let condition of postObject.conditions[ind].conditionsList;let i=index;">
                      <td>{{i+1}}</td>
                      <td>
                        <span *ngIf="!condition['edit']">{{condition.conditionName}}</span>
                        <input *ngIf="condition['edit']" [(ngModel)]="condition.conditionName" />
                      </td>
                      <td>
                        <span *ngIf="!condition['edit']">{{condition.task}}</span>
                        <input *ngIf="condition['edit']" [(ngModel)]="condition.task" />
                      </td>
<!--                      <td>-->
<!--                        <ng-container *ngIf="!condition.cohortValue || !condition.cohortValue.operator">-->
<!--                          {{condition.cohortLabels}}-->
<!--                        </ng-container>-->
<!--                        <ng-container *ngIf="condition.cohortValue && condition.cohortValue.operator">-->
<!--                          {{condition.cohortLabels}} {{condition.cohortValue.operator}}-->
<!--                          {{condition?.cohortValue?.cohortCompareLabels}} {{condition.cohortValue.value}}-->
<!--                          {{condition.cohortValue.date}}-->
<!--                        </ng-container>-->
<!--                      </td>-->
                      <td>
                        <ng-container *ngIf="!condition.cohortValue || !condition.cohortValue.operator">

                                    <span  [innerHTML]="condition.cohortLabels"></span></ng-container>
<ng-container *ngIf="condition.cohortValue && condition.cohortValue.operator">
 <span [innerHTML]="condition.cohortLabels"></span>
 <span [innerHTML]="condition.cohortValue.operator"></span>
 <span [innerHTML]="condition.cohortValue.cohortCompareLabels"></span>
 <span [innerHTML]="condition.cohortValue.value"></span>
 <span [innerHTML]="condition.cohortValue.date"></span>
</ng-container>
                      </td>
                      <td>
                        <ng-container *ngIf="condition.cohortValue">
                          {{condition?.cohortValue?.questionID || 'NA'}}
                        </ng-container>
                      </td>
                      <td>
                        <ng-container  *ngIf="condition?.cohortValue && condition?.cohortValue?.compare_task">
                        {{condition?.cohortValue?.compare_task}}
                        </ng-container>
                      </td>
                      <td>
                         <ng-container *ngIf="condition?.cohortValue && condition?.cohortValue?.compare_questionID">
                        {{condition?.cohortValue?.compare_questionID}}
                           </ng-container>
                      </td>

                      <td>
                        <ng-container
                          *ngIf="condition.cohortValue && condition.cohortValue['dropdownOptions'] && condition.cohortValue['dropdownOptions'].length>0">
                          <span *ngFor="let item of condition.cohortValue['dropdownOptions']">{{item}}{{','}}</span>
                        </ng-container>
                      </td>
                      <td>{{condition.hasResponse? 'Yes': 'No'}}</td>
                      <td>
                        <button [disabled]="hideIcon(ind)">
                          <i class="fa fa-eye" (click)="editList(condition,i, ind, item)"></i>
                        </button>
                        <i class="fa fa-trash" (click)="remove(condition, i, ind, item)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</fieldset>
